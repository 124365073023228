<template>
  <b-modal scrollable id="coa-modal" size="xl" title="Select COA" @show="getList" @hidden="onHidden">
    <div class="mb-3 ml-auto" style="width: 30%">
      <b-input-group>
        <template #append>
          <b-button variant="primary" @click="getList">
            <b-icon icon="search" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <b-form-input
          v-model="search"
          placeholder="Search Account Number"
          @keyup.enter="getList"
          data-cy="search-coa-number-input"
        ></b-form-input>
      </b-input-group>
    </div>
    <TheTable
      :records="records"
      :fields="fields"
      :loading="loading"
      selectable
      @row-selected="onTableSelect"
      select-mode="single"
    >
      <template #cell(checkbox)="{ item }">
        <b-form-radio v-model="selectedItem" :value="item" />
      </template>
    </TheTable>
    <template #modal-footer>
      <div class="d-flex w-100">
        <div class="d-flex align-items-center ml-2">
          <label class="mr-2 mt-1">Entries</label>
          <b-form-select :value="perPage" :options="[10, 25, 50, 100]" @change="handleEntries" style="width: auto">
          </b-form-select>
        </div>
        <b-pagination
          :value="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @change="handlePagination"
          class="mb-0"
        >
        </b-pagination>

        <b-button variant="primary" @click="onSubmit">Submit</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { debounce } from 'lodash'

export default {
  data() {
    return {
      tableFields: [
        {
          key: 'id',
          label: 'ID',
          viewRecord: false,
          size: '50px',
        },
        {
          key: 'checkbox',
          label: '',
          viewRecord: true,
          size: '50px',
        },
        {
          key: 'account_name',
          label: 'Account Name',
          viewRecord: true,
          size: '180px',
        },
        {
          key: 'account_number',
          label: 'Account Number',
          viewRecord: true,
          size: '100px',
          tdClass: 'font-weight-bold',
        },
        {
          key: 'account_type',
          label: 'Account Type',
          viewRecord: false,
          size: '100px',
        },
        {
          key: 'allowable',
          label: 'Allowable/Non-Allowable',
          viewRecord: true,
          formatter: (value) => {
            return value?.toLowerCase?.().includes('allowable') ? value : value ? 'Allowable' : 'Non-Allowable'
          },
          size: '150px',
        },
        {
          key: 'focus_setting',
          label: 'FOCUS ID',
          viewRecord: true,
          formatter: (value) => {
            return value?.focus_id
          },
          size: '100px',
        },
        {
          key: 'ssoi_setting',
          label: 'SSOI ID',
          viewRecord: true,
          formatter: (value) => {
            return value?.ssoi_id
          },
          size: '100px',
        },
      ],
      records: [],
      currentPage: 1,
      perPage: 50,
      rows: 0,
      loading: false,
      selectedItem: undefined,
      search: '',
    }
  },
  watch: {
    search() {
      this.searchList()
    },
  },
  computed: {
    fields() {
      return this.tableFields.filter((field) => field.viewRecord)
    },
  },
  methods: {
    getList() {
      this.loading = true
      const params = {
        params: {
          pagination: { page: this.currentPage, pageSize: this.perPage },
          filters: {
            account_number: {
              $contains: this.search,
            },
          },
        },
      }

      this.$api
        .get(`/coas?company_id=${this.company.id}`, params)
        .then((response) => {
          this.records = response.data.data || []
          const { page, total } = response.data.pagination
          this.currentPage = page
          this.rows = total
        })
        .catch((error) => {
          console.log(error)
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        })
        .finally(() => {
          this.loading = false
        })
    },
    searchList: debounce(function () {
      this.getList()
    }, 900),
    handlePagination(currentPage) {
      this.currentPage = currentPage
      this.getList()
    },
    handleEntries(value) {
      this.currentPage = 1
      this.perPage = value
      this.perPage = value
    },
    onTableSelect(items) {
      this.selectedItem = items[0]
    },
    onSubmit() {
      if (this.selectedItem) {
        this.$emit('onSelect', this.selectedItem)
        this.$bvModal.hide('coa-modal')
      }
    },
    onHidden() {
      this.selectedItem = undefined
    },
  },
}
</script>

<template>
  <b-modal
    id="focus-form-modal"
    scrollable
    title="FOCUS: NEW REPORT"
    size="xl"
    @show="getPrefillData"
    @hidden="reset"
    :ok-title="okTitle"
  >
    <div class="text-center" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <ValidationObserver v-else ref="focusForm">
      <b-form>
        <ValidationProvider
          v-for="comp in formComponents"
          :key="comp.label"
          :name="comp.name"
          :vid="comp.name"
          :rules="bindRules(comp.name)"
          v-slot="{ errors }"
        >
          <b-form-group
            :label="comp.name === 'isAudited' ? '' : comp.label"
            :label-for="comp.name"
            :name="comp.name"
            invalid-feedback="This field is required"
            :state="errors[0] ? false : null"
            :description="comp.description"
            :class="[comp.type === 'array_of_object' ? (haveProposed ? '' : 'd-none') : '']"
          >
            <template v-if="comp.type === 'array_of_object'">
              <div class="d-flex align-items-center" style="gap: 4px; justify-content: end">
                <b-button variant="secondary" type="button" class="mb-2" @click="clearAll(comp.name)"
                  >Clear All
                </b-button>
              </div>
            </template>
            <component
              :is="bvComponents[comp.type]"
              v-bind="bindAttributes(comp)"
              v-on="bindEvents(comp)"
              :state="errors[0] ? false : null"
            >
              <template v-if="comp.name === 'isAudited'">{{ comp.label }}</template>
              <template v-for="tableSchema in comp.schema" v-slot:[`cell(${tableSchema.name})`]="data">
                <ValidationProvider :name="data.item.name" v-slot="{ errors }">
                  <b-form-group :name="data.item.name" class="mb-0">
                    <b-input-group :prepend="tableSchema.type === 'currency' ? '$' : ''" size="sm">
                      <component
                        :is="bvComponents[tableSchema.type]"
                        :state="errors[0] ? false : null"
                        v-bind="bindTableAttributes(comp.name, tableSchema, data.index)"
                        v-on="bindTableEvents(tableSchema, comp.name, data.index)"
                      />
                    </b-input-group>
                  </b-form-group>
                </ValidationProvider>
              </template>
              <template #cell(clear)="{ index }">
                <b-button variant="secondary" type="button" @click="clearRow(comp.name, index)">Clear </b-button>
              </template>
            </component>
          </b-form-group>
        </ValidationProvider>
      </b-form>
    </ValidationObserver>
    <template #modal-footer>
      <b-row>
        <b-col md="12" style="text-align: center">
          <b-button variant="secondary" @click="$bvModal.hide('focus-form-modal')"> Cancel </b-button>
          <b-button v-if="step > 1" class="ml-2" variant="secondary" @click="step -= 1"> Back </b-button>
          <b-button variant="primary" class="ml-2" @click="onSubmit">
            {{ step < prefills?.length ? 'Next' : 'Submit' }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { omitBy, isNil } from 'lodash'
import FormTimeReportInput from './TimeReportInput.vue'
import { cloneDeep, omit } from 'lodash'

export default {
  name: 'FocusFormModal',
  components: {
    FormTimeReportInput,
  },
  props: {
    header: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      tableLoading: false,
      form: {},
      bvComponents: {
        input: 'b-form-input',
        currency: 'NumberFormattedInput',
        select: 'b-form-select',
        number: 'b-form-input',
        date: 'b-form-datepicker',
        radio: 'b-form-radio-group',
        checkbox: 'b-form-checkbox',
        'select-multiple': 'v-select',
        array_of_object: 'TheTable',
        time_report: 'FormTimeReportInput',
      },
      step: 1,
      prefills: [],
      maxTableQty: 1,
    }
  },
  computed: {
    formComponents() {
      let formComp = []

      if (this.prefills?.length) {
        formComp = this.prefills[this.step - 1]
      }
      return formComp.map((prefill) => {
        return {
          ...prefill,
          label: prefill.label,
          name: prefill.name,
          type: prefill.type,
          validation: prefill.validation,
          options: prefill.values,
          description: prefill.type === 'currency' ? 'Please enter numeric value only' : '',
        }
      })
    },
    okTitle() {
      if (this.isNotLastStep) return 'Next'
      return 'Submit'
    },
    haveProposed() {
      return this.form.haveProposedOwnershipEquitySubordinatedLiabilities === true
    },
    prefillValues() {
      return this.$store.getters['company/focusPrefill']
    },

    isLastStep() {
      return this.step === this.prefills?.length
    },

    isNotLastStep() {
      return !this.isLastStep
    },
  },
  methods: {
    ...mapActions('loader', ['setLoading', 'setDescription']),

    getPrefillData() {
      this.loading = true
      this.$api
        .get('/report/focus/prefill-form-data')
        .then((res) => {
          if (res.data?.data?.prefill) {
            this.prefills = res.data.data?.prefill

            const quarterMapping = {
              '03/31': 'Q1',
              '06/30': 'Q2',
              '09/30': 'Q3',
              '12/31': 'Q4',
            }
            const monthMapping = {
              '01': 'JAN',
              '02': 'FEB',
              '03': 'MAR',
              '04': 'APR',
              '05': 'MAY',
              '06': 'JUN',
              '07': 'JUL',
              '08': 'AUG',
              '09': 'SEP',
              10: 'OCT',
              11: 'NOV',
              12: 'DEC',
            }
            const period = this.prefillValues?.endDate?.split('/').slice(0, 2).join('/')
            const periodYear = this.prefillValues?.endDate?.split('/').slice(-1)[0].slice(-2)
            const currentYear = parseInt(new Date().getFullYear().toString().substr(-2))
            const endDate =
              this.company.focus_reporting_frequency === 'Monthly'
                ? `${monthMapping[period?.split('/')[0] || '01']} ${periodYear || currentYear}`
                : `${quarterMapping[period || '03/31']} ${periodYear || currentYear}`

            this.form.endDate = endDate

            if (this.prefillValues) {
              this.form = {
                ...this.form,
                ...res.data?.data?.prefillValues,
                consolidated: this.prefillValues.consolidated,
                // excessLiabilities: this.prefillValues.excessLiabilities,
                exemption: this.prefillValues.exemption,
                isAudited: this.prefillValues.isAudited,
                rule: this.prefillValues.rule,
                ownershipEquitySubordinatedLiabilities: this.prefillValues.ownershipEquitySubordinatedLiabilities,
                haveProposedOwnershipEquitySubordinatedLiabilities:
                  this.prefillValues.ownershipEquitySubordinatedLiabilities?.length > 0 ? true : false,
              }
            }
          } else {
            this.$showError('Unable to fetch api records')
          }
        })
        .catch((err) => {
          console.log(err)
          this.$showError(err.response?.data?.error?.message || 'Unable to fetch api records')
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatNumber(numStr, name) {
      if (!numStr) return numStr
      const num = parseFloat(numStr)

      const isNegative = num < 0
      const absoluteNum = Math.abs(num)
      const formattedNum = absoluteNum.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

      return isNegative ? `(${formattedNum.toString()})` : formattedNum.toString()
    },
    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.focusForm.validate().then((success) => {
        if (!success) return
        if (this.isNotLastStep) {
          this.step = this.step + 1
        } else {
          if (success) {
            if (this.form.ownershipEquitySubordinatedLiabilities) {
              const emptyObj = this.form.ownershipEquitySubordinatedLiabilities.some((obj) => {
                if (!obj) return true
                return Object.values(obj).some((val) => {
                  return val === ''
                })
              })
              if (emptyObj) {
                this.$showError('Please fill the information in Ownership Equity Subordinated Liabilities')
                return
              }
            }

            delete this.form.haveProposedOwnershipEquitySubordinatedLiabilities
            const form = omitBy(this.form, isNil)

            this.setLoading(true)
            this.setDescription('Generating report...')
            console.log(`form`, form)
            this.$api
              .post(`/report/focus/generate?company_id=${this.company.id}`, {
                data: {
                  ...this.header,
                  skip_validate: true,
                  prefill: form,
                },
              })
              .then((res) => {
                if (res.data?.data?.report) {
                  this.$showSuccess('Report submitted successfully')
                  this.$emit('success', res.data.data.report)
                  this.$bvModal.hide('focus-form-modal')
                } else {
                  this.$showError('Unable to submit report')
                }
              })
              .catch((err) => {
                this.$showError(err.response.data.error.message || 'Unable to submit report')
              })
              .finally(() => {
                this.setLoading(false)
                this.setDescription('')
              })
          }
        }
      })
    },
    bindAttributes(comp) {
      const defaultAttributes = {
        id: comp.name,
        value: this.form[comp.name],
        disabled: this.loading,
      }
      if (comp.type === 'select') {
        return {
          ...defaultAttributes,
          placeholder: 'Select...',
          options: comp.options,
        }
      }
      if (comp.type === 'date') {
        return {
          ...defaultAttributes,
          type: 'date',
        }
      }
      if (comp.type === 'time_report') {
        return {
          ...defaultAttributes,
          class: 'w-100',
        }
      }
      if (comp.type === 'checkbox') {
        return {
          ...defaultAttributes,
          options: [{ text: 'Yes', value: true }],
          checked: this.form[comp.name],
        }
      }
      if (comp.type === 'radio') {
        return {
          ...defaultAttributes,
          options: comp.values || [
            { text: 'Yes', value: true },
            { text: 'No', value: false },
          ],
          checked: this.form[comp.name],
        }
      }
      if (comp.type === 'currency') {
        return {
          ...defaultAttributes,
          icon: true,
        }
      }
      if (comp.type === 'array_of_object') {
        const fields = comp.schema
          .map((a) => Object.assign({}, a, { key: a.name, size: '150px' }))
          .concat([{ key: 'clear', name: 'clear', label: '', size: '75px' }])
        const records = Array.from(Array(comp.max_qty), (x, index) => Object.assign({}, { id: (index + 1).toString() }))
        return {
          ...defaultAttributes,
          records,
          fields,
          loading: this.loading,
        }
      }
      return {}
    },
    bindEvents(comp) {
      if (comp.type === 'array_of_object') {
        return {}
      }
      const defaultEvents = {
        input: (e) => {
          this.form = {
            ...this.form,
            [comp.name]: e,
          }
        },
      }
      if (comp.type === 'date' || comp.type === 'time_report') {
        return {
          change: (e) => {
            this.form = {
              ...this.form,
              [comp.name]: e,
            }
          },
        }
      }
      return defaultEvents
    },
    bindTableAttributes(name, tableSchema, tableIdx) {
      const defaultAttributes = {
        id: `${tableSchema.name}_${tableIdx}`,
        value: this.form[name] ? (this.form[name][tableIdx] ? this.form[name][tableIdx][tableSchema.name] : '') : '',
        disabled: this.loading,
        size: 'sm',
        busy: this.tableLoading,
      }
      if (tableSchema.type === 'date') {
        return {
          ...defaultAttributes,
          'date-format-options': {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },
          style: 'width: 100%',
          placeholder: 'MM/DD/YYYY',
          disabled: this.loading,
        }
      }
      if (tableSchema.type === 'select') {
        return {
          ...defaultAttributes,
          placeholder: 'Select...',
          options: tableSchema.values,
        }
      }
      return defaultAttributes
    },
    bindTableEvents(tableSchema, tableName, tableIdx) {
      const defaultEvents = {
        input: (e) => {
          if (!this.form[tableName]) this.form[tableName] = []
          if (!this.form[tableName][tableIdx]) {
            this.form[tableName][tableIdx] = {
              type: '',
              name: '',
              inOrOut: '',
              date: '',
              amount: '',
              renew: '',
            }
          }
          this.form[tableName][tableIdx] = {
            ...this.form[tableName][tableIdx],
            [tableSchema.name]: e,
          }
        },
      }
      if (tableSchema.type === 'select') {
        return {
          change: (e) => {
            if (!this.form[tableName]) this.form[tableName] = []
            if (!this.form[tableName][tableIdx]) {
              this.form[tableName][tableIdx] = {
                type: '',
                name: '',
                inOrOut: '',
                date: '',
                amount: '',
                renew: '',
              }
            }
            this.form[tableName][tableIdx] = {
              ...this.form[tableName][tableIdx],
              [tableSchema.name]: e,
            }
          },
        }
      }
      return defaultEvents
    },
    clearAll(tableName) {
      this.tableLoading = true
      this.$nextTick(() => {
        delete this.form[tableName]
        this.tableLoading = false
      })
    },
    clearRow(name, index) {
      this.tableLoading = true
      this.$nextTick(() => {
        if (this.form[name].length === 1) {
          delete this.form[name]
          this.tableLoading = false
          return
        }
        this.form[name] = this.form[name].filter((a, idx) => idx !== index)
        this.tableLoading = false
      })
    },
    bindRules(name) {
      const defaultRules = 'required'
      const unRequiredFields = [
        'excessLiabilities',
        'isAudited',
        'ownershipEquitySubordinatedLiabilities',
        'operatingLeasesLiabilities',
        'rightOfUseAssets',
        'receivablesFromBrokerDealers',
        'receivablesFromNonCustomers',
        'receivablesFromNonBrokerDealers',
      ]
      if (unRequiredFields.includes(name)) {
        return ''
      }
      return defaultRules
    },
    reset() {
      this.form = {}
      this.maxTableQty = 1
      this.step = 1
    },
  },
}
</script>

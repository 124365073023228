<template>
  <div>
    <div class="d-flex justify-content-between pb-3" style="gap: 15px">
      <div class="d-flex align-items-center" style="flex: 0 1 auto; width: 60%">
        <b-form-select
          v-model="selectedPeriod"
          @change="clearMultiselect"
          :options="periodOptions"
          size="md"
          class="mr-3 mb-0 w-25"
          :disabled="tableLoading"
        >
        </b-form-select>

        <div class="form-group mr-3 mb-0 mt-1" style="width: 80%">
          <MultiSelectInput v-model="multiselectValue" :options="multiselectOptions" :disabled="tableLoading">
          </MultiSelectInput>
        </div>

        <b-form-select
          v-model="selectedYear"
          :options="yearOptions"
          size="md"
          class="mb-0 w-25"
          :disabled="tableLoading"
        >
        </b-form-select>
      </div>

      <div class="d-flex align-items-center">
        <b-button
          variant="primary"
          size="sm"
          :disabled="tableData.length === 0"
          class="mr-3"
          @click="downloadReport('view')"
        >
          Preview PDF
        </b-button>
        <b-button variant="primary" size="sm" :disabled="tableData.length === 0" @click="downloadReport('download')">
          Download PDF
        </b-button>
      </div>
    </div>
    <ReportTable
      :table-loading="tableLoading"
      :fields="fields"
      :static-fields="staticFields"
      :table-data="tableData"
      :is-no-data="isNoData"
    />
  </div>
</template>

<script>
import { numberFormatter, manageReportFiles } from '@/utils/helpers'
import { mapActions } from 'vuex'
import ReportTable from '@/components/Reports/ReportTable.vue'

const NonAllowableColumns = [
  {
    id: 1,
    key: 'id',
    label: 'ID',
    editRecord: false,
    viewRecord: false,
    type: 'text',
  },
  {
    id: 2,
    key: 'account_name',
    label: 'Account Name',
    editRecord: false,
    viewRecord: true,
    type: 'text',
  },
  {
    id: 3,
    key: 'account_number',
    label: 'Account Number',
    editRecord: false,
    viewRecord: true,
    type: 'text',
  },
  {
    id: 4,
    key: 'allowable',
    label: 'Allowable',
    editRecord: false,
    viewRecord: true,
    type: 'text',
  },
  {
    id: 5,
    key: 'focus_id',
    label: 'FOCUS ID',
    editRecord: false,
    viewRecord: false,
    type: 'text',
  },
  {
    id: 6,
    key: 'focus_code',
    label: 'FOCUS',
    editRecord: false,
    viewRecord: true,
    type: 'text',
  },
  {
    id: 7,
    key: 'ssoi_id',
    label: 'SSOI ID',
    editRecord: false,
    viewRecord: false,
    type: 'text',
  },
  {
    id: 8,
    key: 'ssoi_code',
    label: 'SSOI',
    editRecord: false,
    viewRecord: false,
    type: 'text',
  },
]

export default {
  name: 'NonAllowable',
  components: { ReportTable },
  data: () => ({
    fields: [],
    staticFields: ['account_name', 'account_number', 'allowable', 'focus_code'],
    isNoData: false,
    tableData: [],
    status: '',
    // table action
    periodOptions: [
      {
        value: 'Month',
        text: 'Month',
      },
      {
        value: 'Quarter',
        text: 'Quarter',
      },
      {
        value: 'Year',
        text: 'Year',
      },
    ],
    selectedPeriod: 'Month',
    selectedYear: parseInt(new Date().getFullYear().toString().substr(-2)),
    multiselectValue: null,
  }),
  created() {
    this.getAvailableTimeReports()
  },
  watch: {
    query: {
      handler() {
        if (this.query.length > 0) {
          this.getReport()
        }
      },
      immediate: true,
    },
  },
  computed: {
    yearOptions() {
      let options = []
      for (let i = 0; i < 5; i++) {
        options.push({
          value: parseInt(new Date().getFullYear().toString().substr(-2)) - i,
          text: parseInt(new Date().getFullYear().toString()) - i,
        })
      }
      return options
    },
    multiselectOptions() {
      let options = []
      if (this.selectedPeriod === 'Month') {
        for (let i = 0; i < 12; i++) {
          options.push({
            value: new Date(new Date().getFullYear(), i, 1).toLocaleString('default', { month: 'short' }).toUpperCase(),
            text: new Date(new Date().getFullYear(), i, 1).toLocaleString('default', { month: 'short' }).toUpperCase(),
          })
        }
      } else if (this.selectedPeriod === 'Quarter') {
        for (let i = 0; i < 4; i++) {
          options.push({
            value: `Q${i + 1}`,
            text: `Q${i + 1}`,
          })
        }
      } else if (this.selectedPeriod === 'Year') {
        options.push(
          {
            value: '1st HALF',
            text: '1st HALF',
          },
          {
            value: '2nd HALF',
            text: '2nd HALF',
          },
        )
      } else {
        options = []
      }
      return options
    },
    query() {
      let data = []
      this.multiselectValue?.forEach((item) => {
        if (item.value === '1st HALF') {
          for (let i = 1; i <= 6; i++) {
            data.push(
              `${new Date(this.selectedYear, i, 0).toLocaleString('default', { month: 'short' })} ${this.selectedYear}`,
            )
          }
          data.push(`Q1 ${this.selectedYear}`)
          data.push(`Q2 ${this.selectedYear}`)
        } else if (item.value === '2nd HALF') {
          for (let i = 6; i <= 12; i++) {
            data.push(
              `${new Date(this.selectedYear, i, 0).toLocaleString('default', { month: 'short' })} ${this.selectedYear}`,
            )
          }
          data.push(`Q3 ${this.selectedYear}`)
          data.push(`Q4 ${this.selectedYear}`)
        } else {
          data.push(`${item.value} ${this.selectedYear}`)
        }
      })
      return data
    },
    tableLoading() {
      return this.status === 'loading'
    },
  },
  methods: {
    ...mapActions({
      setLoading: 'loader/setLoading',
      setPdf: 'report/setPdf',
    }),
    async getReport() {
      this.status = 'loading'
      this.tableData = []
      try {
        const response = await this.$api.get('/dashboard/al-nonal-sheet', {
          params: {
            time_reports: this.query,
            company_id: this.company.id,
          },
        })
        const assetRecords = this.filterSortRecord(response.data?.data?.Assets?.data) || []
        const liabilitiesRecords = this.filterSortRecord(response.data?.data?.Liabilities?.data) || []
        const equityRecords = this.filterSortRecord(response.data?.data?.["Shareholders' Equity"]?.data) || []

        this.fields = NonAllowableColumns.filter((_) => _.viewRecord)

        if (assetRecords.length === 0 && liabilitiesRecords.length === 0 && equityRecords.length === 0) {
          this.status = 'error'
          this.isNoData = true
          return
        }

        this.query.forEach((item) => {
          this.fields.push({
            key: item,
            label: `${item.split('_')[0].toUpperCase()}`,
            sortable: true,
            viewRecord: true,
          })
        })

        if (assetRecords.length > 0) {
          const { name, ...rest } = response.data?.data?.Assets?.total || {}
          this.tableData.push({
            type: 'Assets',
            records: assetRecords,
            totals: {
              name,
              totalsByMonth: Object.values(rest).map((value) => numberFormatter(value)),
            },
          })
        }
        if (liabilitiesRecords.length > 0) {
          const { name, ...rest } = response.data?.data?.Liabilities?.total || {}
          this.tableData.push({
            type: 'Liabilities',
            records: liabilitiesRecords,
            totals: {
              name,
              totalsByMonth: Object.values(rest).map((value) => numberFormatter(value)),
            },
          })
        }
        if (equityRecords.length > 0) {
          const { name, ...rest } = response.data?.data?.["Shareholders' Equity"]?.total || {}
          this.tableData.push({
            type: "Shareholders' Equity",
            records: equityRecords,
            totals: {
              name,
              totalsByMonth: Object.values(rest).map((value) => numberFormatter(value)),
            },
          })
        }
        this.status = 'success'
      } catch (error) {
        this.status = 'error'
        this.$showError(error.message || 'Unable to fetch api records')
      }
    },
    filterSortRecord(records) {
      const columns = ['account_name', 'account_number', 'allowable', 'focus_code']
      this.query.forEach((item) => {
        columns.push(item.split(' ').join('_').toLowerCase())
      })

      return records?.map((obj) => {
        return columns.reduce((acc, key) => {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (key === 'allowable') {
              acc[key] = obj[key]?.toLowerCase()?.includes('allowable')
                ? obj[key]
                : obj[key]
                  ? 'Allowable'
                  : 'Non-Allowable'
              return acc
            }
            if (key !== 'account_name' && key !== 'account_number' && key !== 'allowable' && key !== 'focus_code') {
              acc[key] = numberFormatter(obj[key])
            } else {
              acc[key] = obj[key]
            }
          }
          return acc
        }, {})
      })
    },
    clearMultiselect() {
      this.multiselectValue = []
    },
    downloadReport(mode) {
      this.setLoading(true)
      const params = {
        params: {
          time_reports: this.query,
          download: true,
        },
      }
      this.$api
        .get(`/dashboard/al-nonal-sheet?company_id=${this.company.id}`, params)
        .then((res) => {
          if (!res.data?.data) {
            this.$showInfo('There is no report.')
            return
          }
          const reportName = this.query.join('_').toUpperCase()
          const createdPDF = manageReportFiles(res.data)
          if (mode === 'download') {
            createdPDF.download(`Non_Allowable_Assets_${reportName}.pdf`)
            return
          }
          createdPDF.getDataUrl((dataUrl) => {
            this.setPdf({
              src: dataUrl,
              title: `Non_Allowable_Assets_${reportName}}`,
            })
            this.$bvModal.show('PdfModal')
          })
        })
        .catch((err) => {
          this.$showError(err.response?.data?.error?.message || 'Unable to fetch api records')
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    async getAvailableTimeReports() {
      this.status = 'loading'
      try {
        const response = await this.$api.get(`/trial-balances/available-time-report?company_id=${this.company.id}`)
        console.log(response)
        if (response.status === 200 && response.data?.data?.length > 0) {
          this.multiselectValue = [
            {
              value: response.data.data[0].time_report.split(' ')[0],
              text: response.data.data[0].time_report.split(' ')[0],
            },
          ]
          this.selectedYear = parseInt(response.data.data[0].time_report.split(' ')[1])
        } else {
          this.multiselectValue = [
            {
              value: new Date().toLocaleString('default', { month: 'short' }).toUpperCase(),
              text: new Date().toLocaleString('default', { month: 'short' }).toUpperCase(),
            },
          ]
        }
        this.status = 'success'
      } catch (error) {
        this.status = 'error'
        this.$showError(error.message || 'Unable to fetch api records')
      }
    },
  },
}
</script>

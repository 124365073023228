<template>
  <div>
    <b-modal
      id="non-options-inventory-form-modal"
      :title="modalTitle"
      size="lg"
      @show="getPrefillData"
      @ok="onSubmit"
      @hidden="resetForm"
      scrollable
      ok-title="Submit"
    >
      <div class="text-center" v-if="isLoading">
        <b-spinner></b-spinner>
      </div>
      <ValidationObserver v-else ref="nonOptionsInventoryFormModal">
        <b-form>
          <template v-for="comp in formComponents">
            <ValidationProvider
              v-if="comp.type === 'date'"
              :name="comp.name"
              :rules="comp.validation"
              v-slot="{ errors }"
            >
              <b-form-group :label="comp.label" :label-for="comp.name" :invalid-feedback="errors[0]">
                <b-form-datepicker
                  :id="comp.name"
                  :state="errors[0] ? false : null"
                  v-model="form[comp.name]"
                  :disabled="!comp.isEditable"
                ></b-form-datepicker>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              v-if="comp.type === 'input'"
              :name="comp.name"
              :rules="comp.validation"
              v-slot="{ errors }"
            >
              <b-form-group :label="comp.label" :label-for="comp.name" :invalid-feedback="errors[0]">
                <b-form-input
                  :id="comp.name"
                  type="text"
                  :state="errors[0] ? false : null"
                  v-model="form[comp.name]"
                  :disabled="!comp.isEditable"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              v-if="comp.type === 'number'"
              :name="comp.name"
              :rules="comp.validation"
              v-slot="{ errors }"
            >
              <b-form-group :label="comp.label" :label-for="comp.name" :invalid-feedback="errors[0]">
                <b-form-input
                  :id="comp.name"
                  type="number"
                  :state="errors[0] ? false : null"
                  v-model.number="form[comp.name]"
                  :disabled="!comp.isEditable"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              v-if="comp.type === 'select'"
              :name="comp.name"
              :rules="comp.validation"
              v-slot="{ errors }"
            >
              <b-form-group :label="comp.label" :label-for="comp.name" :invalid-feedback="errors[0]">
                <b-form-select
                  :id="comp.name"
                  :options="comp.options"
                  v-model="form[comp.name]"
                  :state="errors[0] ? false : null"
                  :disabled="!comp.isEditable"
                ></b-form-select>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              v-if="comp.type === 'currency'"
              :name="comp.name"
              :rules="comp.validation"
              v-slot="{ errors }"
            >
              <b-form-group
                :label="comp.label"
                :label-for="comp.name"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null"
              >
                <NumberFormattedInput
                  v-if="comp.name === 'haircut_amount'"
                  :name="comp.name"
                  :state="errors[0] ? false : null"
                  v-model="form.haircut_amount"
                  :disabled="!isSubTypeOther"
                  icon
                  :errors="errors[0]"
                />
                <NumberFormattedInput
                  v-if="comp.name === 'purch_pr'"
                  :name="comp.name"
                  :state="errors[0] ? false : null"
                  v-model="form.purch_pr"
                  icon
                  :errors="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
          </template>

          <ValidationProvider v-if="mode === 'add'" rules="required" name="Report Date" v-slot="{ errors }">
            <b-form-group label="Report Date" :invalid-feedback="errors[0]">
              <b-datepicker
                :state="errors[0] ? false : null"
                v-model="form.report_date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              ></b-datepicker>
            </b-form-group>
          </ValidationProvider>
        </b-form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import NumberFormattedInput from '../NumberFormattedInput.vue'

export default {
  name: 'NonOptionsInventoryFormModal',
  components: {
    NumberFormattedInput,
  },
  props: {
    haircutData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        id: null,
        identifier: '',
        cusip_isin: '',
        type: '',
        subtype: '',
        currency: '',
        qty: '',
        purch_pr: '',
        long_or_short: '',
        closing_price: '',
        maturity: '',
        tot_pos_val: '',
        bonds_face_val: '',
      },
      clonedForm: {},
      formComponents: [],
      bvComponents: {
        input: 'b-form-input',
        currency: 'b-form-input',
        select: 'b-form-select',
        number: 'b-form-input',
        date: 'b-form-datepicker',
      },
    }
  },
  computed: {
    modalTitle() {
      return this.mode === 'add' ? 'Add Non-Options Inventory' : 'Edit Non-Options Inventory'
    },
    isSubTypeOther() {
      return this.form.subtype === 'OTHER'
    },
  },
  watch: {
    'form.subtype': {
      handler: function (oldValue) {
        if (oldValue === 'OTHER') this.form.haircut_amount = ''
      },
    },
  },
  methods: {
    onSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.nonOptionsInventoryFormModal.validate().then((valid) => {
        if (!valid) {
          this.$showError('Please fill all the required fields')
          return
        }
        if (Object.keys(this.form).every((key) => this.form[key] === this.clonedForm[key])) {
          this.$emit('hc-updated')
          this.$bvModal.hide('non-options-inventory-form-modal')
          return
        }
        this.$emit(this.mode === 'add' ? 'hc-added' : 'hc-updated', this.form)
        this.$bvModal.hide('non-options-inventory-form-modal')
      })
    },
    getPrefillData() {
      this.isLoading = true
      this.$api
        .get('non-options-inventories/prefill')
        .then((response) => {
          const nonRequiredFields = [
            'maturity',
            'undue_concentration_basis',
            'undue_concentration_charge',
            'haircut_amount',
          ]
          this.formComponents = response.data?.data.map((item) => {
            return {
              label: item.label,
              name: item.name,
              type: item.values ? 'select' : item.type,
              validation: item.dependentFieldName ? '' : nonRequiredFields.includes(item.name) ? '' : 'required',
              options: item.values?.map((v) => {
                return {
                  value: v,
                  text: v,
                }
              }),
              dependentFieldName: item.dependentFieldName,
              dependentFieldValue: item.dependentFieldValue,
              isEditable: item.isEditable,
            }
          })

          this.formComponents.forEach((component) => {
            if (component.name === 'subtype') {
              component.options = component.options.map((item) => {
                return {
                  value: item.value.value,
                  text: item.text.label,
                }
              })
            }
          })

          if (this.mode === 'edit') this.bindForm()
        })
        .catch((error) => {
          this.$showError(error.response?.data?.error?.message || 'Something went wrong')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    bindForm() {
      this.form.report_date = this.haircutData.report_date
      this.form.id = this.haircutData.id
      this.formComponents.forEach((item) => {
        this.form[item.name] = this.haircutData[item.name]
        if (item.name === 'haircut_amount') {
          this.form.subtype === 'OTHER' ? (item.isEditable = true) : (item.isEditable = false)
        }
        if (item.name === 'name_description') {
          item.isEditable = false
          item.validation = ''
        }
      })
    },
    resetForm() {
      this.form = {
        id: null,
        identifier: '',
        cusip_isin: '',
        type: '',
        subtype: '',
        currency: '',
        qty: '',
        purch_pr: '',
        long_or_short: '',
        closing_price: '',
        maturity: '',
        tot_pos_val: '',
        bonds_face_val: '',
        report_date: '',
      }
    },
  },
}
</script>
